exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-pizzarte-js": () => import("./../../../src/pages/pizzarte.js" /* webpackChunkName: "component---src-pages-pizzarte-js" */),
  "component---src-pages-reservas-js": () => import("./../../../src/pages/reservas.js" /* webpackChunkName: "component---src-pages-reservas-js" */),
  "component---src-templates-o-nosso-menu-js": () => import("./../../../src/templates/o-nosso-menu.js" /* webpackChunkName: "component---src-templates-o-nosso-menu-js" */)
}

